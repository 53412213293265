import moment from 'moment'
import {
  NotificationProgrammatic as Notification,
  ToastProgrammatic as Toast,
} from 'buefy'

//* get cookie
export const getCookie = function (cookieName) {
  let cookieValue = null
  try {
    cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith(cookieName))
      .split('=')[1]
  } catch (err) {
    console.log('error retrieveing cookie fcm token')
    console.log(err)
  }
  return cookieValue
}

export const setCookie = function (cookieName, value, expMilisecond, path) {
  let d = new Date()
  d.setTime(d.getTime() + expMilisecond) // 3 days
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cookieName + '=' + value + ';' + expires + ';path=' + path
}

export const deleteCookie = function (cookieName) {
  document.cookie =
    cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

//*format date. this function take Date() as instance
export const formatDate = function (date, format) {
  if (!format) {
    format = 'YYYY-MM-DD'
  }

  return moment.utc(date).local().format(format)
}

//* this is a function to show toast
//* type can be is-success or is-danger
//* position can be is-top or is-bottom
export const showToast = function (message, type, position) {
  Toast.open({
    message: message,
    type: type,
    duration: 5000,
    position: position != null ? position : 'is-top',
  })
}

export const showNotification = function (
  message,
  position,
  type,
  duration,
  hasIcon,
  onClickCallback
) {
  const notif = Notification.open({
    duration: duration ? duration : 5000,
    message,
    position: position ? position : 'is-bottom-right',
    type: type ? type : 'is-info',
    hasIcon: hasIcon ? hasIcon : false,
  })
  if (onClickCallback) {
    notif.$on('click', onClickCallback())
  }
}

export const round = function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const getLocation = async function () {
  return new Promise((resolve, reject) => {
    if (!('geolocation' in navigator)) {
      reject(new Error('Geolocation is not available.'))
    }
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        resolve(pos)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

//* Capitalize first letter in a word
export const capitalizeFirstLetter = function (word) {
  if (word) {
    let splitted = word.toLowerCase().split('')

    //*uppercase
    splitted[0] = splitted[0].toUpperCase()

    return splitted.join('')
  }
  return null
}

/* camelCase to Sentence Case
https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-sentence-case-text
 */

export const camelToSentenceCase = function (word) {
  let result = word.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

/*
   https://www.geeksforgeeks.org/snake-case-given-sentence/
 */
export const sentenceToSnakeCase = function (word) {
  let result = ''
  for (let i = 0; i < word.length; i++) {
    // Converting space to underscor
    if (word.charAt(i) === ' ') result += '_'
    // If not space, convert into lower character
    else result += word.charAt(i).toLowerCase()
  }
  return result
}

/*
https://hisk.io/javascript-snake-to-camel/
 */
export const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  )

/*
 * Count and format actual duration
 * @parameter : actual work duration (in minute) and status (string)
 */
export const formatActualOvertimeDuration = (actualWorkInMinute, status) => {
  if (actualWorkInMinute == null) {
    return '0 hours'
  }

  //* normal work hour in minute (8 hour * 60 minute = 480 minute)
  let normalWorkHourInMinute = 8 * 60

  //* only if the status is accepted, we recognize it as an overtime
  if (status === 'accepted') {
    //* only if actual work duration is more than normal work hou (8 hour = 480 minute)
    if (actualWorkInMinute > normalWorkHourInMinute) {
      //* actual overtime is actual work hour in minute - 480 minute (8 hour)
      let actualOvertimeInMinute = actualWorkInMinute - normalWorkHourInMinute
      return formatMinute(actualOvertimeInMinute)
    }
  }

  return '0 hours'
}

/*
 * this will format duration in minute into X hours Y minutes
 * @parameter : duration in minute (Number), hour Format (string), minuteFormat(string)
 */
export const formatMinute = (durationInMinute, hourFormat, minuteFormat) => {
  let hourF = hourFormat ? hourFormat : 'hours'
  let minuteF = minuteFormat ? minuteFormat : 'minutes'

  if (durationInMinute == null) {
    return null
  }
  if (durationInMinute < 60) {
    return durationInMinute.toString() + minuteF
  }
  //*  count hour
  let hour = Math.floor(durationInMinute / 60)
  //* count minute
  let minute = durationInMinute % 60
  return hour.toString() + hourF + ' ' + minute.toString() + minuteF
}

export const getDuration = (startDate, endDate, unitTime) => {
  let momentStart = moment(startDate)
  // console.log('moment star tis')
  // console.log(momentStart)
  let momentEnd = moment(endDate)
  // console.log('moment end is')
  // console.log(momentEnd)

  let duration = momentEnd.diff(momentStart, unitTime)

  return duration
}

/*
 * This function will determine if a user can edit his/her data
 *  Admin can edit anything
 *  user(employee) can only update his data
 *  example route = /people/detail/:id
 */
export const canEdit = (user, routeId) => {
  return user.isAdmin || user.id === parseInt(routeId)
}

/*
 * Generate List of number
 */
export const generateRangeNumber = (startYear, endYear) => {
  let years = []
  for (let i = startYear; i <= endYear; i++) {
    years.push(i)
  }
  return years
}

export const getArrayOldNewVal = (obj, requiredFields) => {
  let arrayOldNewVal = []
  for (const [key, value] of Object.entries(obj)) {
    if (key.includes('old')) {
      //replace old_ with empty to get original field key
      let originalFieldKey = key.replace('old', '')
      // compare value with value of   new_ key
      if (value !== obj['new' + originalFieldKey]) {
        arrayOldNewVal.push({
          key: originalFieldKey,
          oldVal: value,
          newVal: obj['new' + originalFieldKey],
          status: obj['statusNew' + originalFieldKey],
          required: requiredFields.includes('statusNew' + originalFieldKey),
        })
      }
    }
  }
  return arrayOldNewVal
}

export const getTotalChange = (request) => {
  let totalChange = 0
  // Idea : Look for key with old_, compare it with new_, if value is different, increment total change by 1
  for (const [key, value] of Object.entries(request)) {
    if (!key.includes('Files')) {
      if (key.includes('old')) {
        //replace old_ with empty to get original field key
        let originalFieldKey = key.replace('old', '')
        // compare value with value of   new_ key
        if (value !== request['new' + originalFieldKey]) totalChange += 1
      }
    }
  }
  return totalChange
}

export const formatCurrency = (
  number,
  locales = ['ban', 'id'],
  options = {
    style: 'currency',
    currency: 'IDR',
  },
  withoutSymbol = false
) => {
  if (withoutSymbol) {
    return new Intl.NumberFormat(locales, options)
      .format(number ? number : 0)
      .replace(options.currency, '')
  }
  return new Intl.NumberFormat(locales, options).format(number ? number : 0)
}
